import { FC, useEffect } from "react";
import React from "react";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import Translate from "../../Translate/Translate";
import translate from "src/lib/translate";
import styled from "@emotion/styled";
import CollectionTitle from "../../StyledComponents/CollectionTitle";
import { useSearchParams } from "react-router-dom";
import { useBloc } from "@blac/react";
import {
  APP_BREAKPOINT,
  APP_CONTENT_WIDTH_APPOINTMENT
} from "src/constants/layout";
import { getSupportedUserLanguage } from "src/lib/i18next";
import AppointmentsBloc, {
  AppointmentSanityDetails
} from "src/state/AppointmentsBloc/AppointmentsBloc";
import SanityBlockContent from "src/ui/components/SanityBlockContent/SanityBlockContent";
import { DateFormats, dateLocal } from "src/lib/date";
import { AppointmentAvatar } from "src/constants/icons";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { toast } from "src/state/state";

const AppointmentContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EventContent = styled.div`
  ol {
    padding-left: 1.5rem;
  }

  a {
    color: var(--color-sunrise-blue);
  }
`;

const GradientWrap = styled.div`
  position: relative;
  width: 100%;
  height: 8.5rem;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    height: 6.5rem;
  }
`;

const GradientForm = styled.div`
  position: absolute;
  width: 160%;
  height: 70vh;
  bottom: 0;
  background: linear-gradient(65deg, #80aeff 0%, #f7bde6 52%, #ffbd70 100%);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    width: 260%;
  }
`;

const DefaultAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -1.875rem;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: var(--color-cream);
  object-fit: contain;
  border: 0.25rem solid var(--color-gray-light);
`;

const Picture = styled.img`
  object-fit: cover;
  position: absolute;
  bottom: -1.875rem;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: var(--color-cream);
  border: 0.25rem solid var(--color-gray-light);
`;

const Wrap = styled.div`
  max-width: ${APP_CONTENT_WIDTH_APPOINTMENT / 16}rem;
  margin-top: 3rem;

  @media screen and (max-width: ${APP_BREAKPOINT}px) {
    padding: 0 1.5rem;
  }
`;

const ActionsWrap = styled.div`
  margin-top: 1.5rem;
  border-top: 0.125rem solid var(--color-cream-dark);
  border-bottom: 0.125rem solid var(--color-cream-dark);
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ActionContainer = styled.div`
  background-color: var(--color-white);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: var(--light-shadow);

  &:hover {
    box-shadow: var(--large-shadow);
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
`;

const AppointmentDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const [appointmentDetails, setAppointmentDetails] =
    React.useState<AppointmentSanityDetails>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [, { getAppointment }] = useBloc(AppointmentsBloc);

  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get("id") ?? "";
  const appointment = getAppointment(appointmentId);

  const { start, end, location } = appointment ?? {};

  const userLanguage = getSupportedUserLanguage();

  const weekday = dateLocal(start, { locale: userLanguage }).format(
    DateFormats.DISPLAY_WEEKDAY_MONTH_DAY
  );

  const timeZone = dateLocal(start, { locale: userLanguage }).format("z");

  const startTime = dateLocal(start, { locale: userLanguage }).format(
    DateFormats.DISPLAY_TIME_ALT
  );

  const endTime = dateLocal(end, { locale: userLanguage }).format(
    DateFormats.DISPLAY_TIME_ALT
  );

  const hasZoomLink = location?.includes("zoom");

  useEffect(() => {
    if (!appointment) return;

    const fetchCmsDetails = async () => {
      setLoading(true);

      try {
        const data = await AppointmentsBloc.getAppointmentCmsDetails(
          appointment.type
        );

        setAppointmentDetails(data);
      } catch (error) {
        toast.error("error_appointment_details_load_failed");

        reportErrorSentry(error);
      } finally {
        setLoading(false);
      }
    };

    void fetchCmsDetails();
  }, [appointment]);

  if (loading || !appointmentDetails || !appointment) {
    return null;
  }

  const { config } = appointment;

  const primaryActionVisible =
    config.primaryAction.visible?.(appointment) ?? true;

  const secondaryActionVisible =
    config.secondaryAction.visible?.(appointment) ?? true;

  return (
    <InDialog
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
      simple
      backdropDismiss={false}
      title={translate("appointment.popup.title")}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
    >
      <AppointmentContent>
        <GradientWrap>
          <GradientForm />
          {appointmentDetails.avatar ? (
            <Picture
              src={appointmentDetails.avatar.url}
              alt={appointmentDetails.eventName}
            />
          ) : (
            <DefaultAvatar>
              <AppointmentAvatar />
            </DefaultAvatar>
          )}
        </GradientWrap>

        <Wrap>
          <h1
            className="as-h4"
            style={{
              textAlign: "center"
            }}
          >
            {appointmentDetails.eventName}
          </h1>
          <h2
            className="as-body2 color-c-80"
            style={{ marginTop: "24px", textAlign: "center" }}
          >
            <Translate
              msg="appointment.eventDateTime"
              variables={{
                context: "detail",
                weekday,
                startTime,
                endTime,
                timeZone
              }}
            />
          </h2>
          {hasZoomLink && (
            <p
              className="body1 color-c-80"
              style={{ margin: "8px 0 0", textAlign: "center" }}
            >
              {appointmentDetails.joiningInstructions}
            </p>
          )}

          <ActionsWrap>
            {primaryActionVisible && (
              <Action
                onClick={(): void => config.primaryAction.onClick(appointment)}
              >
                <ActionContainer>{config.primaryAction.icon}</ActionContainer>
                <Translate
                  msg={config.primaryAction.label}
                  variables={{ context: "detail" }}
                />
              </Action>
            )}

            {secondaryActionVisible && (
              <Action
                onClick={(): void =>
                  config.secondaryAction.onClick(appointment)
                }
              >
                <ActionContainer>{config.secondaryAction.icon}</ActionContainer>
                <Translate
                  msg={config.secondaryAction.label}
                  variables={{ context: "detail" }}
                />
              </Action>
            )}
          </ActionsWrap>

          {appointmentDetails.eventContent.map((content) => {
            return (
              <>
                <CollectionTitle style={{ marginTop: "24px" }}>
                  {content.contentTitle}
                </CollectionTitle>

                <EventContent>
                  <SanityBlockContent blocks={content.content} />
                </EventContent>
              </>
            );
          })}
        </Wrap>
      </AppointmentContent>
    </InDialog>
  );
};

export default AppointmentDialog;
